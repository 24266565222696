@import '@taiga-ui/core/styles/taiga-ui-local';

[tuiAppearance][data-appearance='text'] {
  color: var(--tui-text-primary);
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;

  @include appearance-hover() {
    color: var(--tui-text-secondary);
  }

  @include appearance-active() {
    color: var(--tui-text-secondary);
  }
}

[tuiAppearance][data-appearance='text-primary'] {
  color: var(--tui-text-action);
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;

  @include appearance-hover() {
    color: var(--tui-text-action-hover);
  }

  @include appearance-active() {
    color: var(--tui-text-action-hover);
  }
}

[tuiAppearance][data-appearance='text-primary-on-accent'] {
  color: var(--tui-text-primary-on-accent-1);
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;

  @include appearance-hover() {
    opacity: 0.75;
  }

  @include appearance-active() {
    opacity: 0.9;
  }
}

[tuiAppearance][data-appearance='text-secondary-on-accent'] {
  color: var(--tui-text-primary-on-accent-1);
  font-weight: normal !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  opacity: 0.75;

  @include appearance-hover() {
    opacity: 1;
  }

  @include appearance-active() {
    opacity: 0.9;
  }
}

[tuiAppearance][data-appearance='primary-on-accent'] {
  background-color: var(--tui-background-elevation-1);
  color: var(--tui-background-accent-opposite);

  @include appearance-hover() {
    color: var(--tui-background-accent-opposite-hover);
  }

  @include appearance-active() {
    color: var(--tui-background-accent-opposite-pressed);
  }
}

[tuiAppearance][data-appearance='outline-on-accent'] {
  color: var(--tui-text-primary-on-accent-1);
  box-shadow: inset 0 0 0 1px var(--tui-text-primary-on-accent-1);

  @include appearance-hover() {
    opacity: 0.75;
  }

  @include appearance-active() {
    opacity: 0.9;
  }
}
