@import url('https://fonts.googleapis.com/css2?family=Mohave:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Mulish:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root {
  // widths
  --width-1: 24rem;
  --width-2: 32rem;
  --width-3: 48rem;
  --width-4: 64rem;
  --width-5: 80rem;

  // FFB colors
  --ffb-white: #ffffff;
  --ffb-black: #000000;
  --ffb-green: #009478;
  --ffb-green-light: #44dda0;
  --ffb-green-dark: #095b4c;
  --ffb-green-dark-hover: #0b6e5c;
  --ffb-blue: #3267ff;
  --ffb-yellow: #fedf8f;
  --ffb-orange: #ff6250;
  --ffb-red: #ff4e4e;
  --ffb-purple: #6201ef;
  --ffb-pink: #f0c0b1;
  --ffb-lavender: #beaff2;
  --ffb-blue-dark: #251a61;
  --ffb-blue-dark-overlay: #251a6180;
  --ffb-white-overlay: #ffffff10;
  --ffb-green-gradient: linear-gradient(
    0.25turn,
    #067661,
    50%,
    var(--ffb-green)
  );

  // FFB/Education colors
  --ffb-education-background-accent-1: #9000ff;
  --ffb-education-background-accent-1-hover: #af48ff;
  --ffb-education-background-accent-1-active: #6600b6;
  --ffb-education-selection-background: #9000ff90;

  // Taiga UI
  --tui-font-heading: 'Mohave', sans-serif; // Font for headings
  --tui-font-text: 'Mulish', sans-serif; // Font for text

  // Swiper (carousel)
  --swiper-theme-color: var(--tui-background-accent-1);

  // ngx-json-viewer
  --ngx-json-null: #999;
  --ngx-json-null-bg: transparent;
  --ngx-json-undefined: #999;

  // Misc
  --ffb-radius-round: 9999px;
  --tui-radius-xl: 1rem;
}

// Taiga UI themes
:root,
[tuiTheme='light'] {
  // Backgrounds
  --tui-background-accent-1: var(--ffb-green);
  --tui-background-accent-1-hover: #00ba98;
  --tui-background-accent-1-pressed: #007862;
  --tui-background-accent-1-light: #d9efeb;
  --tui-background-accent-2: var(--ffb-orange);
  --tui-background-accent-2-hover: #c74b3e;
  --tui-background-accent-2-pressed: #a13d32;
  --tui-background-base-alt-2: #ededed;
  // Other
  --tui-service-selection-background: #d9efeb90;
  // Text
  --tui-text-primary: #1b1b1f;
  --tui-text-secondary: #49484c;
  --tui-text-tertiary: #807f84;
  --tui-text-action: var(--tui-background-accent-1);
  --tui-text-action-hover: var(--tui-background-accent-1-hover);
}

[tuiTheme='dark'] {
  // Text
  --tui-text-action: var(--tui-background-accent-1);
  --tui-text-action-hover: var(--tui-background-accent-1-hover);
}
