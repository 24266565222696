/* You can add global styles to this file, and also import other style files */
@import 'normalize.css';
@import 'basscss-layout';
@import 'basscss-margin';
@import 'basscss-padding';
@import 'basscss-position';
@import 'basscss-typography';
@import 'basscss-align';
@import 'basscss-flexbox';

@import 'ionic';
@import 'swiper';

@import 'reset';
@import 'variables';
@import 'media-queries';
@import 'helpers';
@import 'taiga-ui';
@import 'appearances';
