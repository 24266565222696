[tuiButton] {
  &:not(.font-weight-normal) {
    font-weight: 700;
  }
  &:not(.lowercase) {
    text-transform: uppercase;
    letter-spacing: 0.08em;
  }
}

tui-dialogs {
  > section {
    max-height: 100vh;

    tui-dialog {
      > .t-content,
      > .t-wrapper {
        max-width: 100vw;
      }

      > .t-content {
        padding: calc(env(safe-area-inset-top) + 1.5rem) 1.5rem !important;
      }

      > .t-content {
        > section {
          white-space: pre-line;
        }
      }
    }
  }
}

tui-root._mobile .t-close {
  top: calc(env(safe-area-inset-top) + var(--space-2)) !important;
  right: var(--space-2) !important;
}

hr {
  height: 1px;
}

[tuiSkeleton]._skeleton
  [tuiWrapper][data-appearance='textfield'][data-state='readonly'] {
  background: var(--tui-background-base);
}

tui-icon {
  @media print {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
}

tui-mobile-ios-loader {
  top: env(safe-area-inset-top) !important;
}
