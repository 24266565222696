@import '@taiga-ui/core/styles/taiga-ui-local';
@import 'media-queries';

// region with/height
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.screen-width {
  width: 100vw;
}

.screen-height {
  height: 100vh;
}

.min-full-width {
  min-width: 100%;
}

.min-full-height {
  min-height: 100%;
}

.max-full-width {
  max-width: 100%;
}

.max-full-height {
  max-height: 100%;
}

.min-screen-width {
  min-width: 100vw;
}

.min-screen-height {
  min-height: 100vh;
}

.min-content-width {
  min-width: max-content;
}

.max-content-width {
  width: max-content;
}

.height-auto {
  height: auto !important;
}

.fit-text-height {
  height: 1em !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.fit-text {
  height: 1em !important;
  width: 1em !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.fit-root-text {
  height: 1rem !important;
  width: 1rem !important;
  font-size: 1rem !important;
  line-height: 1rem !important;
}

.max-width-5 {
  max-width: var(--width-5);
}

@for $i from 1 through 5 {
  .min-width-#{$i} {
    min-width: var(--width-#{$i});
  }
}

// endregion

// region margins
.mtn1 {
  margin-top: calc(var(--space-1) * -1);
}
.mtn2 {
  margin-top: calc(var(--space-2) * -1);
}
.mtn3 {
  margin-top: calc(var(--space-3) * -1);
}
.mtn4 {
  margin-top: calc(var(--space-4) * -1);
}
.mrn1 {
  margin-right: calc(var(--space-1) * -1);
}
.mrn2 {
  margin-right: calc(var(--space-2) * -1);
}
.mrn3 {
  margin-right: calc(var(--space-3) * -1);
}
.mrn4 {
  margin-right: calc(var(--space-4) * -1);
}
.mbn1 {
  margin-bottom: calc(var(--space-1) * -1);
}
.mbn2 {
  margin-bottom: calc(var(--space-2) * -1);
}
.mbn3 {
  margin-bottom: calc(var(--space-3) * -1);
}
.mbn4 {
  margin-bottom: calc(var(--space-4) * -1);
}
.mln1 {
  margin-left: calc(var(--space-1) * -1);
}
.mln2 {
  margin-left: calc(var(--space-2) * -1);
}
.mln3 {
  margin-left: calc(var(--space-3) * -1);
}
.mln4 {
  margin-left: calc(var(--space-4) * -1);
}
// endrefion

// region flexbox
.flex-0 {
  flex: 1 1 0;
}

.flex-1 {
  flex: 1;
}

.flex-100 {
  flex: 1 1 100%;
}

.flex-none {
  flex: none;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

@for $i from 1 through 4 {
  .gap#{$i} {
    gap: var(--space-#{$i}) !important;
  }
  .row-gap#{$i} {
    row-gap: var(--space-#{$i});
  }
  .column-gap#{$i} {
    column-gap: var(--space-#{$i});
  }
}
// endregion

// region position
.sticky {
  position: sticky;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
// endregion

// region colors
.color-accent-1 {
  color: var(--tui-background-accent-1) !important;
}

a.color-accent-1 {
  &:active {
    color: var(--tui-background-accent-1-pressed);
  }

  &:hover {
    color: var(--tui-background-accent-1-hover);
  }
}

.color-accent-2 {
  color: var(--tui-background-accent-2) !important;
}

a.color-accent-2 {
  &:active {
    color: var(--tui-background-accent-2-pressed);
  }

  &:hover {
    color: var(--tui-background-accent-2-hover);
  }
}

.color-success {
  color: var(--tui-status-positive);
}

.color-error {
  color: var(--tui-status-negative);
}

.color-warning {
  color: var(--tui-status-warning);
}

.color-black {
  color: var(--ffb-black);
}

.color-white {
  color: var(--ffb-white);
}

.color-text-primary {
  color: var(--tui-text-primary);
}

.color-text-secondary {
  color: var(--tui-text-secondary);
}

.color-text-tertiary {
  color: var(--tui-text-tertiary);
}

.color-text-primary-on-accent-1 {
  color: var(--tui-text-primary-on-accent-1);
}

.color-text-secondary-on-accent-1 {
  color: var(--tui-text-primary-on-accent-1);
  opacity: 0.75;
}

.color-border-normal {
  color: var(--tui-border-normal);
}

.opaque {
  opacity: 1;
}

.transparent {
  background: none;
  opacity: 0;
}
// endregion

// region backgrounds
.bg-base,
.bg-base:not(div) > div {
  background-color: var(--tui-background-base);
}

.bg-base-alt,
.bg-base-alt:not(div) > div {
  background-color: var(--tui-background-base-alt);
}

.bg-base-alt-2,
.bg-base-alt-2:not(div) > div {
  background-color: var(--tui-background-base-alt-2);
}

.bg-neutral-1,
.bg-neutral-1:not(div) > div {
  background-color: var(--tui-background-neutral-1);
}

.bg-neutral-2,
.bg-neutral-2:not(div) > div {
  background-color: var(--tui-background-neutral-2);
}

.bg-accent-1,
.bg-accent-1:not(div) > div {
  background-color: var(--tui-background-accent-1) !important;
}

.bg-accent-1-light,
.bg-accent-1-light:not(div) > div {
  background-color: var(--tui-background-accent-1-light) !important;
}

.bg-white,
.bg-white:not(div) > div {
  background-color: var(--ffb-white);
}

.bg-green,
.bg-green:not(div) > div {
  background-color: var(--ffb-green);
}

.bg-green-light,
.bg-green-light:not(div) > div {
  background-color: var(--ffb-green-light);
}

.bg-green-dark,
.bg-green-dark:not(div) > div {
  background-color: var(--ffb-green-dark);
}

.bg-green-gradient,
.bg-green-gradient:not(div) > div {
  background: var(--ffb-green-gradient), var(--tui-background-accent-1);
}

.bg-blue,
.bg-blue:not(div) > div {
  background-color: var(--ffb-blue);
}

.bg-blue-dark,
.bg-blue-dark:not(div) > div {
  background-color: var(--ffb-blue-dark);
}

.bg-yellow,
.bg-yellow:not(div) > div {
  background-color: var(--ffb-yellow);
}

.bg-orange,
.bg-orange:not(div) > div {
  background-color: var(--ffb-orange);
}

.bg-purple,
.bg-purple:not(div) > div {
  background-color: var(--ffb-purple);
}

.bg-pink,
.bg-pink:not(div) > div {
  background-color: var(--ffb-pink);
}

.bg-lavender,
.bg-lavender:not(div) > div {
  background-color: var(--ffb-lavender);
}

.bg-white-overlay,
.bg-white-overlay:not(div) > div {
  background-color: var(--ffb-white-overlay);
}

.bg-blue-dark-overlay,
.bg-blue-dark-overlay:not(div) > div {
  background-color: var(--ffb-blue-dark-overlay);
}

.bg-error,
.bg-error:not(div) > div {
  background-color: var(--tui-status-negative-pale);
}

.bg-error-fill,
.bg-error-fill:not(div) > div {
  background-color: var(--tui-status-negative);
}

.bg-education-accent-1,
.bg-education-accent-1:not(div) > div {
  background-color: var(--ffb-education-background-accent-1);
}

.bg-status-negative,
.bg-status-negative:not(div) > div {
  background-color: var(--tui-status-negative);
}

.bg-status-negative-pale,
.bg-status-negative-pale:not(div) > div {
  background-color: var(--tui-status-negative-pale);
}

.bg-status-negative-pale-hover,
.bg-status-negative-pale-hover:not(div) > div {
  background-color: var(--tui-status-negative-pale-hover);
}

.bg-status-positive,
.bg-status-positive:not(div) > div {
  background-color: var(--tui-status-positive);
}

.bg-status-positive-pale,
.bg-status-positive-pale:not(div) > div {
  background-color: var(--tui-status-positive-pale);
}

.bg-status-positive-pale-hover,
.bg-status-positive-pale-hover:not(div) > div {
  background-color: var(--tui-status-positive-pale-hover);
}

.bg-status-warning,
.bg-status-warning:not(div) > div {
  background-color: var(--tui-status-warning);
}

.bg-status-warning-pale,
.bg-status-warning-pale:not(div) > div {
  background-color: var(--tui-status-warning-pale);
}

.bg-status-warning-pale-hover,
.bg-status-warning-pale-hover:not(div) > div {
  background-color: var(--tui-status-warning-pale-hover);
}

.bg-status-neutral,
.bg-status-neutral:not(div) > div {
  background-color: var(--tui-status-neutral);
}

@for $i from 1 through 2 {
  .bg-cards-0#{$i} {
    background-image: url('/assets/images/cards-0#{$i}.png');
    background-size: cover;
    background-position: center;
  }
}
// endregion

// region border radius
.border-radius-xs {
  border-radius: var(--tui-radius-xs);
}
.border-radius-s {
  border-radius: var(--tui-radius-s);
}
.border-radius-m {
  border-radius: var(--tui-radius-m);
}
.border-radius-l {
  border-radius: var(--tui-radius-l);
}
.border-radius-xl {
  border-radius: var(--tui-radius-xl);
}
.border-radius-round {
  border-radius: var(--ffb-radius-round) !important;
}
// endregion

// region borders
.border-normal {
  border: 1px solid var(--tui-border-normal);
}
.border-hover {
  border: 1px solid var(--tui-border-hover);
}
.border-focus {
  border: 1px solid var(--tui-border-focus);
}
.border-light {
  border: 1px solid var(--tui-background-base-alt-2);
}
.border-bottom-normal {
  border-bottom: 1px solid var(--tui-border-normal);
}
.border-bottom-light {
  border-bottom: 1px solid var(--tui-background-base-alt-2);
}
// endregion

// region grid
.grid {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-column-gap: var(--space-2);
  grid-row-gap: var(--space-2);

  &.gap0 {
    grid-column-gap: 0;
    grid-row-gap: 0;
  }

  @for $i from 1 through 4 {
    &.gap#{$i} {
      grid-column-gap: var(--space-#{$i});
      grid-row-gap: var(--space-#{$i});
    }

    &.column-gap#{$i} {
      grid-column-gap: var(--space-#{$i});
    }

    &.row-gap#{$i} {
      grid-row-gap: var(--space-#{$i});
    }
  }

  @include breakpoint('gt-sm') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @for $i from 1 through 4 {
      &.grid-#{$i}-columns {
        grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
      }
    }
  }
  @include breakpoint('lt-md') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include breakpoint('lt-sm') {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .full-grid-width {
    grid-column: 1/-1;
  }
}
// endregion

.transform-ease-in-out {
  transition-property: transform;
  transition-duration: var(--tui-duration, 0.3s);
  transition-timing-function: ease-in-out;
}

.upside-down {
  transform: rotate(180deg);
}

.semi-bold {
  font-weight: 700 !important;
}

.uppercase-semi-bold {
  text-transform: uppercase;
  font-weight: 700 !important;
  letter-spacing: 0.08em;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-x-auto {
  @include scrollbar-hidden();
  overflow-x: auto;
}

.pre-line {
  white-space: pre-line;
}

.white-space-normal {
  white-space: normal;
}

.hidden {
  visibility: hidden;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
  * {
    cursor: not-allowed !important;
  }
}

.tui-font-text {
  font-family: var(--tui-font-text);
}

.tui-font-heading {
  font-family: var(--tui-font-heading);
}

.font-weight-normal {
  font-weight: normal !important;
}

.break-word {
  word-break: break-word;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@media not print {
  .print-only,
  .print-only * {
    display: none !important;
  }
}

.no-empty:empty {
  display: none;
}
