html,
body {
  height: 100%;
  &:not(.native-platform) {
    background-color: var(--tui-background-accent-1);
  }
}

body:not(.native-platform) {
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-image: url('/assets/images/ffb-logo.png');
    background-repeat: no-repeat;
    background-size: auto calc(var(--space-3) * 4);
    background-position: center center;
    animation: 2s infinite blink;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.axeptio_mount {
  @media print {
    display: none;
  }
}
